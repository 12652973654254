import {
  type Link,
  type LinkInternal,
  MarketCountryCode,
  type Price,
} from '../../api-models'
import type { MobilePlanOffer } from '../../api-specs-b2c-services/mobile-plan/types/mobile-plan-offers'
import { type HttpResponsePayload, createHttpEndpoint } from '../../utils'

// Deprecated to remove in B2CS-533
export type ServiceOffer = {
  id: string
  serviceType: string
  partnerName: string
}

export type PartnerPromoCode = {
  id: string
  partnerName: string
}

// TODO [BRO-4775] Remove legacy types and mapper
export type GetProductResponse = HttpResponsePayload<{
  id: string
  legacyId: string
  link: LinkInternal
  links: Partial<Record<MarketCountryCode, LinkInternal>>
  type: 'refurbished' | 'accessory'
  title: string
  rawTitle: string
  model: string
  subTitleElements: Array<string>
  priceWhenNew?: Price
  isSwapEligible: boolean
  images: Array<{
    url: string
    description: string
    source?: 'CATALOG' | 'PARTNER'
  }>
  carrier: {
    isDisplayed: boolean
    locked: boolean
    name: 'Verizon' | 'AT&T' | 'T-Mobile' | string | null
  }
  tags: Array<string>
  insurancesOffers: {
    additionalBenefits?: Array<string>
  }
  includedServiceOffers: {
    mobilePlans: Array<MobilePlanOffer>
    partnerPromoCodes: Array<PartnerPromoCode>
  }
  eWaste: string
  eWasteData?: {
    sentence: string
    link: Link
  }
  description: string
  mobilePlan?: {
    listingId: number
  }
  vatIncluded?: boolean
  showVerifiedRefurbished: boolean
  comesWith: {
    displayAccessoryExclusionsModal: boolean
    items: Array<{
      icon: string
      text: string
      type: string
    }>
  }
  brand: string
  tracking: {
    categoryId: string
    categoryName: string
    color: string
  }
  isProductRematched: boolean
  seo: {
    slug: string
    description: string
    marketsLinks: Array<{ countryCode: MarketCountryCode; slug: string }>
  }
}>

/**
 * @see {@link https://devportal.backmarket.io/catalog/default/api/product-page-service-api/definition#/product-page/get_product}
 */
export const getProduct = createHttpEndpoint<GetProductResponseV2>({
  method: 'GET',
  operationId: 'getProduct',
  path: '/product-page/products/:productId',
})

export type GetProductResponseV2 = HttpResponsePayload<{
  productId: string
  type: 'Refurbished' | 'Brand new' | 'Digital'
  titles: {
    raw: string
    default: string
  }
  subtitleElements: Array<string>
  priceWhenNew: Price
  isTradeInEligible: boolean
  images: Array<{
    url: string
    description: string
    source?: 'CATALOG' | 'PARTNER'
  }>
  brand: string
  model: string
  carrier: {
    isDisplayed: boolean
    locked: boolean
    name: 'Verizon' | 'AT&T' | 'T-Mobile' | string | null
  }
  comesWith: {
    displayAccessoryExclusionsModal: boolean
    items: Array<{
      icon: string
      text: string
      type: string
    }>
  }
  tracking: {
    categoryId: string
    categoryName: string
    color: string
  }
  seo: {
    slug: string
    description: string
    marketsLinks: Array<{ countryCode: MarketCountryCode; slug: string }>
  }
  isProductRematched: boolean
  showVerifiedRefurbished: boolean
  includedServiceOffers: {
    mobilePlans: Array<MobilePlanOffer>
    partnerPromoCodes: Array<ServiceOffer>
  }
}>
