import { useRuntimeConfig } from '#imports'
import { type MaybeRefOrGetter, computed, onMounted, ref, toValue } from 'vue'

import { reviewsAPI } from '@backmarket/http-api'
import type { Review } from '@backmarket/http-api/src/api-specs-reviews/types/review'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'

import translations from './UserComment.translations'

export function useTranslateComment(review: MaybeRefOrGetter<Review>) {
  const i18n = useI18n()
  const locale = useI18nLocale()
  const logger = useLogger()

  const { openErrorToast } = useTheToast()
  const {
    market: { countryCode: currentCountryCode },
  } = useMarketplace()
  const { FF_REVIEW_TRANSLATION_AUTO } = useRuntimeConfig().public

  const translatedComment = ref('')
  const isTranslated = ref(false)

  const isTranslatable = computed(
    () => toValue(review).countryCode !== currentCountryCode,
  )

  async function fetchTranslationIfMissing({
    openToastOnError = false,
  }: { openToastOnError?: boolean } = {}) {
    // if translatedComment is already set, do not fetch again
    if (translatedComment.value) return

    try {
      const translation = await $httpFetch(reviewsAPI.getReview, {
        pathParams: { reviewId: toValue(review).id },
        queryParams: { locale },
      })

      translatedComment.value = translation.comment
    } catch (error) {
      logger.error(error as string)
      if (openToastOnError)
        openErrorToast({
          title: i18n(translations.errorToastTitle),
          content: i18n(translations.errorToastMessage),
        })
    }
  }

  onMounted(() => {
    const autoTranslateComment =
      isTranslatable.value && FF_REVIEW_TRANSLATION_AUTO
    if (autoTranslateComment) {
      void fetchTranslationIfMissing({ openToastOnError: false })
    }
  })

  return {
    isTranslatable,
    isTranslated,
    fetchTranslationIfMissing,
    translatedComment,
  }
}
