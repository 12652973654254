<template>
  <UserCommentView
    :id
    :average-rate="averageRate"
    :comment
    :country-code="countryCode"
    :customer
    :handled-with-care="handledWithCare"
    :is-highlighted="isHighlighted"
    :language-code="languageCode"
    :originalReview="shouldDisplayEditedReview ? originalReview : undefined"
    :product="displayedProduct"
    :purchase-date="product.purchasedAt"
    :review-date="createdAt"
    :track-review-impression="true"
    :trackingData="{
      onClick: clickTrackingData,
      onImpression: impressionTrackingData,
    }"
    :translated-comment="_translatedComment"
    @toggle-translation="onToggleTranslation"
  >
    <PhotosSlider v-if="isPhotosSliderEnabled" v-bind="props" />
  </UserCommentView>
</template>

<script lang="ts" setup>
import { useRoute, useRuntimeConfig } from '#imports'
import { computed, inject, ref } from 'vue'

import UserCommentView from '@backmarket/nuxt-layer-reviews/review-display/UserCommentView.vue'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'

import { PRODUCT_INJECTION_KEY } from '~/scopes/product/symbols'

import type { DisplayedReview } from '../../models/reviews'
import PhotosSlider from '../PhotosSlider/PhotosSlider.vue'

import { useTranslateComment } from './useTranslateComment'

const props = withDefaults(
  defineProps<
    DisplayedReview & {
      position: number
      isHighlighted?: boolean
      showPhotos?: boolean
    }
  >(),
  { isHighlighted: false, showPhotos: true },
)

// Inject the product data provided by Product.vue
const injectedProduct = inject(PRODUCT_INJECTION_KEY, ref(null))

const route = useRoute()
const experiments = useExperiments()
const config = useRuntimeConfig()

const { fetchTranslationIfMissing, translatedComment: _translatedComment } =
  useTranslateComment(props)

const displayedProduct = injectedProduct?.value
  ? { ...injectedProduct.value, title: injectedProduct.value.rawTitle }
  : props.product

const isPhotosSliderEnabled = computed(() => {
  return props.showPhotos && !config.public.KILL_PHOTOS_IN_REVIEWS
})

// COM-1517 - Clean AB test
const shouldDisplayEditedReview = computed(
  () =>
    props.handledWithCare &&
    experiments['experiment.displayEditedReviews'] !== 'withoutEditedReviews',
)

const impressionTrackingData = {
  reviewPosition: props.position,
  pageType: route.name as string,
}

const clickTrackingData = {
  zone: `review_position_${props.position}`,
  page_type: route.name as string,
}
async function onToggleTranslation() {
  await fetchTranslationIfMissing({ openToastOnError: true })
}
</script>
