<template>
  <RevDrawer
    :close-button-label="i18n(translations.alternativeClose)"
    :large="true"
    :name="id"
    :title="product?.title"
  >
    <template #trigger="{ open }">
      <RevCardCarousel
        class="mt-32"
        desktop-card-width="narrow"
        mobile-card-width="narrow"
        :next-alternative-text="i18n(translations.alternativeNext)"
        :prev-alternative-text="i18n(translations.alternativePrevious)"
        size="extra-small"
      >
        <RevButtonBase
          v-for="photo in attachments"
          :key="photo.uploadId"
          class="!h-full"
          @click="open"
        >
          <RevIllustration
            alt=""
            class="rounded-md !h-[160px] object-cover"
            :height="160"
            sizes="160px"
            :src="photo.url"
            :width="160"
          />
        </RevButtonBase>
      </RevCardCarousel>
    </template>

    <template #body>
      <div class="gap-24 md:flex">
        <RevCarousel
          :alternative-button-label="i18n(translations.alternativeController)"
          :alternative-next="i18n(translations.alternativeNext)"
          :alternative-previous="i18n(translations.alternativePrevious)"
          class="h-full w-auto md:min-w-448"
          :close-button-label="i18n(translations.alternativeClose)"
          current-index-id="gallery-media-viewer"
          name="review-media-viewer"
          :thumbnails
        >
          <RevIllustration
            v-for="(image, index) in carouselImages"
            :key="image.id"
            alt=""
            class="rounded-lg max-h-full max-w-full object-contain"
            :height="480"
            :loading="shouldLoadEagerly(index)"
            sizes="(min-width: 768px) 448px, 328px"
            :src="image.src"
            :width="448"
          />
        </RevCarousel>

        <RevDivider class="my-32 md:hidden" />

        <UserComment v-bind="props" :show-photos="false" />
      </div>
    </template>
  </RevDrawer>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevCardCarousel } from '@ds/components/CardCarousel'
import { RevCarousel } from '@ds/components/Carousel'
import { RevDivider } from '@ds/components/Divider'
import { RevDrawer } from '@ds/components/Drawer'
import { RevIllustration } from '@ds/components/Illustration'

import type { DisplayedReview } from '../../models/reviews'
import UserComment from '../UserComment/UserComment.vue'

import translations from './PhotosSlider.translations'

const props = defineProps<
  DisplayedReview & {
    position: number
    isHighlighted?: boolean
  }
>()

const i18n = useI18n()

const carouselImages = computed(() => {
  return props.attachments?.map((image) => ({
    id: image.uploadId,
    src: image.url,
    alt: '',
  }))
})

const thumbnails = computed(() => {
  return carouselImages.value?.map((image) => ({
    ...image,
    width: 100,
    height: 100,
  }))
})

function shouldLoadEagerly(index: number) {
  return index === 0 ? 'eager' : 'lazy'
}
</script>
